import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import logoMain from "../../../static/icons/eco-logo-main-new.svg"
import fbIcon from "../../../static/icons/fb-icon.svg"

export default function Footer(props) {
  return <> 
  <footer className="footer-container">
  <div className="container  bb--blue pb--01 mb--01">
    <div className="container--inner container--flex container--flex--alignTop">
    <div className="flex--33">
        <h4>Eco Wash Solutions</h4>
        <a href="/"><img className="footer-logoMain" src={logoMain} alt="Eco Wash Solutions" /></a>
        <br />
        <p>We offer a range of washing and detailing services across Auckland.</p>
        {/* Car Wash Solutions<br />
        <a href="#" className="styled-link">ecocarwash.co.nz</a><br /><br />
        Truck Wash Solutions<br />
        <a href="https://ecotruckwash.co.nz" target="_blank" rel="noreferrer" className="styled-link">ecotruckwash.co.nz</a><br /><br />
        Property Wash Solutions<br /> 
        <a href="https://ecopropertyservices.co.nz" target="_blank"  rel="noreferrer" className="styled-link">ecopropertyservices.co.nz</a> */}
        </div>
        <div className="flex--33">
        <h4>Links</h4>
        <ul className="styled-list">
          <li><AnchorLink to="/about" title="About Us" className="styled-link" > 
            <span>About Us</span>
          </AnchorLink></li>
          <li><a className="styled-link" href="https://booking.ecocarwash.co.nz" target="_blank"  rel="noreferrer" >Book a service</a></li>
          <li><a className="styled-link" href="https://paintprotection.ecocarwash.co.nz" target="_blank" rel="noreferrer" >Paint Protection</a></li>
          <li><AnchorLink to="/case-studies" title="Case Studies" className="styled-link" > 
            <span>Case Studies</span>
          </AnchorLink></li>
          <li><AnchorLink to="/#reviews" title="Reviews" className="styled-link" > 
            <span>Testimonials</span>
          </AnchorLink></li>
          <li><AnchorLink to="/#contact" title="Contact Us" className="styled-link" > 
            <span>Contact Us</span>
          </AnchorLink></li>
        </ul>
      </div>
      <div className="flex--33">
      <h4>Contact Us</h4>
      {/* Auckland */}
      <ul className="styled-list">
        <li>M: <a href="tel:021 724 555" className="styled-link">021 724 555</a></li>
        <li>P: <a href="tel:09 215 4117" className="styled-link">09 215 4117</a></li>
        <li>E: <a href="mailto:info@ecowashsolutions.co.nz" className="styled-link">info@ecowashsolutions.co.nz</a></li>
      </ul>
      {/* Hamilton
      <ul className="styled-list">
        <li>P: <a href="tel:0800 758 575" className="styled-link">0800 758 575</a></li>
        <li>M: <a href="tel:021 275 8575" className="styled-link">021 275 8575</a></li>
        <li>E: <a href="mailto:hamilton@ecowashsolutions.co.nz" className="styled-link">hamilton@ecowashsolutions.co.nz</a></li>
      </ul> */}
      <ul className="social-icons">
        <li>
          <a className="social-icon" href="https://www.youtube.com/channel/UCuI2fdoimZqhc0hf1EooS9A" target="_blank" rel="noreferrer"><svg alt="Youtube - Eco Wash Solutions" height="30px" width="40px" viewBox="0 -77 512.00213 512"  xmlns="http://www.w3.org/2000/svg"><path d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0" fill="#f00"/><path d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0" fill="#fff"/></svg></a>
        </li>
        <li>
          <a className="social-icon" href="https://www.facebook.com/cargroomer/" target="_blank" rel="noreferrer">
            <img src={fbIcon} alt="Eco Wash Solutions Facebook" />
           </a>
        </li>

      </ul>

    </div>
    </div>
    </div>
    <div className="container container--flex">
      <div>{props.copyright}</div>
    </div>
  </footer>
  </>
}