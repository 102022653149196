import React, { Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false, subNavActive: false }
  }
  handleClick = () => {
    if (window.innerWidth > 800) {
      return
    }
    this.setState({ active: !this.state.active })
  }

  handleSubNavClick = () => {
    this.setState({ subNavActive: !this.state.subNavActive })
  }

  render() {
    return (
      <> <a
      href="#"
      role="button"
      className={
        "navbar-burger burger " + (this.state.active ? "is-active" : "")
      }
      aria-label="menu"
      data-target="nav-list"
      aria-expanded="false"
      onClick={this.handleClick}
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
      <div className={"nav-container " + (this.state.active ? "is-active" : "")}>
        <nav className="nav-nav ">
          <ul className="nav-list" id="nav-list">
            <li className="nav-list__item" onClick={this.handleClick}>
              <a href="/">Home</a>
            </li>
            <li className="nav-list__item" >
              <AnchorLink to="/about" title="About Us">
                <span>About Us</span>
              </AnchorLink>
            </li>
            <li
              className={"nav-list__item has-subMenu " + (this.state.subNavActive ? "is-open" : "")}
              
            >

                <a href="#" onClick={this.handleSubNavClick}>Services
                <i><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></i>
                </a>

              <div className="nav-list__subMenu">
                <ul>
                  <li>
                    <a href="https://ecocarwash.co.nz">Car Wash Solutions</a>
                  </li>
                  <li>
                    <a href="/truck-wash">Truck Wash Solutions</a>
                  </li>
                  <li>
                    <a href="/property-wash">Property Wash Solutions</a>
                  </li>
                </ul>
              </div>
            </li>
            <li onClick={this.handleClick}><AnchorLink to="/case-studies" title="Case Studies">
            <span>Case Studies</span>
          </AnchorLink></li>
            <li className="nav-list__item" onClick={this.handleClick}>
              <AnchorLink to="/#reviews" title="Testiominials">
                <span>Reviews</span>
              </AnchorLink>
            </li>
            <li className="nav-list__item" onClick={this.handleClick}>
              <AnchorLink to="/#contact" title="Contact Us">
                <span>Contact Us</span>
              </AnchorLink>
            </li>
            <li className="nav-list__item" onClick={this.handleClick}>
              <AnchorLink
                to="http://paintprotection.ecocarwash.co.nz/payment"
                className="nav-item--action"
                title="Make a payment"
              >
                <span>Make a payment</span>
              </AnchorLink>
            </li>
          </ul>
        </nav>
      </div>
      </>
    )
  }
}

export default Navigation
